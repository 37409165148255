export const initMenu = () => {
    const navEl = document.querySelector('.page-navigation');
    const navMenuBoxEl = document.querySelector('.page-navigation__menu-box');

    if (!navEl) {
        return false;
    }

    const toggleNav = () => {
        navEl.classList.toggle('page-navigation--open');
    };

    navMenuBoxEl.addEventListener('click', toggleNav);
};
